import React from "react";

export default class Loading extends React.Component {
    render() {
        return (
            <div className='loading_wrap'>
                <div className='loading'/>
            </div>
        )
    }
}